@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.section__padding {
    padding: 1rem 2rem;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    background-image: linear-gradient(to bottom, #0da82e, #189223, #1b7d19, #1a680f, #175407);
    color: white;
    font-family: inherit;
    padding: 1.35em;
    padding-left: 2.2em;
    font-size: 17px;
    font-weight: 500;
    border-radius: 30px;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em #714da6;
    overflow: hidden;
    position: relative;
    height: 2.4em;
    padding-right: 3.3em;
    cursor:pointer;
   }
   
   .btn .icon {
    background: white;
    margin-right: 0.5em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    box-shadow: 0.1em 0.1em #7b52b9;
    right: 0.1em;
    transition: all 0.3s;
   }
   
   
   .btn .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: #7b52b9;
   }
   
   .btn:hover {
    transform: translateY(-0.1em);
    background-image: linear-gradient(to bottom, #72ca85, #60c170, #4eb859, #3caf41, #27a524);
   }
   
   .btn:active .icon {
    transform: scale(0.95);
   }

.p__poppins{
    font-family: 'Poppins', sans-serif;
}

.section__title{
    font-weight: 700;
    color: white;
    font-size: 2.2rem;
}


.card{
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: saturate(180%) blur(10px);
    transition: transform .2s;
}

.card:hover{
    transform: scale(1.05);
}