.about__section {
    height: 900px;
    background-image: url('../../images/bg2.png');
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}

.about__content {
    margin-top: 4rem;
    margin-inline: 12rem;
}

.about__description {
    color: white;
    text-transform: uppercase;
    margin-top: 3.2rem;
    line-height: 1.8rem;
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
}

.about__button {
    margin-top: 4rem;
}

.about__button-text {
    font-size: 1rem;
}

@media (max-width: 576px) {
    .about__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
        margin-inline: 1.5rem;
    }

    .about__description {
        text-align: center;
        color: white;
        text-transform: uppercase;
        margin-top: 2.5rem;
        line-height: 1.2rem;
        font-weight: 600;
        font-size: 0.8rem;
    }

    .about__button {
        margin-top: 6rem;
        height: 1rem;
    }

    .about__button-text {
        font-size: 0.65rem;
    }

}

@media (min-width: 767px) {
    .about__section {
        height: 800px;
    }

    .about__content {
        margin-top: 3rem;
        margin-inline: 4rem;
    }

    .about__title {
        font-size: 3rem;
    }

    .about__description {
        color: white;
        text-transform: uppercase;
        margin-top: 2.5rem;
        line-height: 1.8rem;
        font-weight: 600;
        font-size: 1rem;
    }

    .about__button {
        margin-top: 6rem;
        height: 3.8rem;
    }

    .about__button-text {
        font-size: 1.2rem;
    }

}

@media (min-width: 992px) {
    .about__section {
        height: 800px;
    }

    .about__content {
        margin-top: 3rem;
        margin-inline: 5rem;
    }

    .about__title {
        font-size: 3rem;
    }

    .about__description {
        color: white;
        text-transform: uppercase;
        margin-top: 2.5rem;
        line-height: 1.8rem;
        font-weight: 600;
        font-size: 1.3rem;
    }

    .about__button {
        margin-top: 6rem;
        height: 3.8rem;
    }

    .about__button-text {
        font-size: 0.8rem;
    }
}

@media (min-width: 1200px) {
    .about__section {
        height: 800px;
    }

    .about__content {
        margin-top: 3rem;
        margin-inline: 17rem;
    }

    .about__title {
        font-size: 2.2rem;
    }

    .btn {
        width: 21rem;
    }

    .about__description {
        color: white;
        text-transform: uppercase;
        margin-top: 2.5rem;
        line-height: 1.8rem;
        font-weight: 600;
        font-size: 1.1rem;
        width: 48rem;
    }

    .about__button {
        margin-top: 6rem;
        height: 3rem;
    }


}

@media screen and (min-width: 1400px) {}