.footer__section {
    background-image: url('../../images/bg5.png');
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;

    height: 100vh;
}

.footer__container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
    gap: 2.5rem 0;
    margin-inline: 10rem;
    padding-top: 9rem;
}

.footer__info {
    padding: 1rem;
    display: grid;
    max-width: 30rem;

    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: saturate(180%) blur(10px);
    transition: transform .2s;
}

.form__info-container {
    max-width: 400px;
    margin-top: 2rem;
    margin-right: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
}

.form__personal-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1rem;
}

.form__name {
    width: max-content;
    height: 2.5rem;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
}

textarea {
    margin-top: 2rem;
    border-radius: 12px;
    padding: 10px;
    height: 6rem;
    width: 26rem;
}

::placeholder {
    color: black;
    font-family: 'Poppins';
    font-weight: 600;
}

.form__button {
    margin-top: 1.8rem;
    width: 10rem;
}

.info {
    display: flex;
    align-items: center;

    column-gap: 10px;
    margin-bottom: 12px;
}

.info img {
    height: 2rem;
    width: 2rem;
}

.info__text {
    color: white;
}

.info__icons {
    display: flex;
    column-gap: 3rem;
    justify-content: center;

    margin-top: 4rem;
    cursor: pointer;   
}

.icon__img{
    transition: transform 0.5s;
}

.icon__img:hover{
    filter: drop-shadow(0 0.2rem 0.25rem rgba(94, 196, 97, 0.8));
    transform: translateY(-0.3rem) ;
}

@media (max-width: 576px) {

    .form{
        align-items: center;
    }

    .footer__section {
        height: 1200px;
    }

    .footer__container {
        grid-template-columns: repeat(1, 2fr);
        margin-inline: 0rem;

        padding-top: 5rem;
    }

    .info__text {
        font-size: 0.9rem;

    }

    .section__title {
        font-size: 1.9rem;
        text-align: center;
    }

    textarea {
        width: 19rem;
        margin-left: 1rem;
    }

    .form__personal-info {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem 0.5rem;
    }

    .footer__info {
        text-align: center;
        padding: 1rem;
        display: grid;

        width: 18rem;
        
    }

    .info__icons {
        flex-direction: row !important;
        column-gap: 2rem;
        justify-content: center;       
    }

    .info {
        flex-direction: column;
        row-gap: 0.5rem;
    }

}

@media (min-width: 768px) {
    .footer__container {
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        gap: 2.5rem 0;
        margin-inline: 5rem;
        justify-items: center;

        padding-top: 5rem;
    }

    .info__icons {
       justify-content: flex-start;
       gap:4rem;       
    }

}

@media (min-width: 992px) {
    .footer__container {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 2.5rem 0;
        margin-inline: 4rem;
        justify-items: stretch;


        padding-top: 5rem;
    }
} 

@media (min-width: 1200px) {
    .footer__container {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 2.5rem 0;
        margin-inline: 4rem;
        justify-items: center;


        padding-top: 8rem;
    }

    .footer__info {
        height: 30rem;
        width: 30rem;
    }

    textarea {
        height: 10rem;
        width: 30rem;
    }

    .form__name{
        width: 14rem;
        height: 3rem;
    }

    .footer__title{
        font-size: 3rem;
    }

}

@media screen and (min-width: 1400px) {}