.app__navbar {
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 8px;
    border-radius: 5px;
    height: 80px;

    position: absolute;
    z-index: 1;
    top: 8px;
    left: 0px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: saturate(180%) blur(10px);
}
 

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: white;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
    font-size: 1.1rem;
    transition: transform 0.3s;
}

.app__navbar-links li:hover{
    color: #6acf7e;
    transform: translateY(-0.2rem) ;
}

.app__navbar-smallscreen {
    display: none;
}
 
.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom, #277437, #22642a, #1d541e, #164512, #103606);
    backdrop-filter: saturate(180%) blur(10px);
    transition: 0.5s ease;

    flex-direction: column;
    z-index: 1;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: white;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    transition: transform 0.3s;
}

.app__navbar-smallscreen_links li:hover {
    transform: translateY(-0.2rem) ;
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 1100px) {
    .app__navbar {
        width: 98%;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        width: 96%;
        padding: 1rem;
    }

    .app__navbar-logo img {
        width: 110px;
    }
}